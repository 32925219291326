import React from "react"
import Layout from "./../../../components/layouts/Default"
import RequestLeftCol from "./../../../components/layouts/partials/pages/request/RequestLeftCol"
import RequestRightCol from "./../../../components/layouts/partials/pages/request/RequestRightCol"
import Wizard from "./../../../components/forms/Wizard"
import Input from "./../../../components/forms/Input"
import CheckboxCard from "./../../../components/forms/CheckboxCard"
import FormMainData from "./../../../components/layouts/partials/pages/request/MainData"

export default function Page({ data, location }) {
  const svg = {
    info: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width={64}
        height={64}
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <circle cx={12} cy={12} r={9} />
        <line x1={12} y1={8} x2="12.01" y2={8} />
        <polyline points="11 12 12 12 12 16 13 16" />
      </svg>
    ),
    neubau: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width={64}
        height={64}
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <polyline points="5 12 3 12 12 3 21 12 19 12" />
        <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
        <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
      </svg>
    ),
    hauskauf: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width={64}
        height={64}
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <line x1={3} y1={21} x2={21} y2={21} />
        <path d="M4 21v-15a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v15" />
        <path d="M9 21v-8a3 3 0 0 1 6 0v8" />
      </svg>
    ),
    anschluss: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width={64}
        height={64}
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
        <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
        <line x1={9} y1={7} x2={10} y2={7} />
        <line x1={9} y1={13} x2={15} y2={13} />
        <line x1={13} y1={17} x2={15} y2={17} />
      </svg>
    ),
    umschuldung: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width={64}
        height={64}
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <line x1={9} y1={14} x2={15} y2={8} />
        <circle cx="9.5" cy="8.5" r=".5" fill="currentColor" />
        <circle cx="14.5" cy="13.5" r=".5" fill="currentColor" />
        <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2" />
      </svg>
    ),
  }

  return (
    <Layout
      title="Baufinanzierung anfragen | Dierda Finanzdienstleistungen"
      description="Wir finden die passende Baufinanzierung für Dich."
      location={location}
      smallFooter
    >
      <section>
        <div className="flex flex-wrap">
          <div className="w-full max-w-screen-lg p-8 lg:w-8/12 lg:order-2 lg:p-16">
            <RequestRightCol>
              <h1 className="text-2xl font-bold md:text-3xl lg:text-4xl">
                Baufinanzierung anfragen
              </h1>
              <form
                action="https://formbackend.dierda.de/anfrage.php"
                method="POST"
              >
                <input type="hidden" name="Anfrage" value="Baufinanzierung" />

                <Wizard>
                  <div>
                    <div className="mt-8 mb-4 ">
                      <h3 className="text-lg font-bold leading-6 text-gray-900">
                        Wünscht Du ein Infogespräch?
                      </h3>
                      <p className="max-w-2xl mt-1 text-sm leading-5 text-gray-500">
                        Hast Du noch Fragen abseits des Darlehens? Wir
                        beantworten alle Deine Fragen rund um Deine neue
                        Immobilie. Diese Informationen sind wichtig, damit wir
                        ungefähr wissen, was Du benötigst.
                      </p>
                    </div>

                    <div className="grid grid-cols-1 gap-4 mb-6">
                      <div>
                        <CheckboxCard
                          label="Infogespräch"
                          name="Infogespräch"
                          value="Ja"
                          id="info"
                          svg={svg.info}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="mt-8">
                      <h3 className="text-lg font-bold leading-6 text-gray-900">
                        Wie hoch soll das Darlehen sein?
                      </h3>
                      <p className="max-w-2xl mt-1 text-sm leading-5 text-gray-500">
                        Diese Informationen sind wichtig, damit wir ungefähr
                        wissen, was Du benötigst.
                      </p>
                    </div>

                    <div className="mb-4">
                      <Input
                        type="number"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        label="Gewünschte Summe in €"
                        id="summe"
                        name="Summe"
                        placeholder="300.000,00"
                        required
                      />
                    </div>

                    <div className="mb-4">
                      <Input
                        type="number"
                        min="0"
                        max="100"
                        label="Gewünschte Laufzeit in Jahren"
                        id="laufzeit"
                        name="Laufzeit"
                        placeholder="10"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mt-8">
                      <h3 className="text-lg font-bold leading-6 text-gray-900">
                        Wofür benötigst Du das Darlehen?
                      </h3>
                      <p className="max-w-2xl mt-1 text-sm leading-5 text-gray-500">
                        Klicke kurz an, ob die folgenden Optionen für Dich
                        relevant sind. Diese Informationen sind wichtig, damit
                        wir ungefähr wissen, was Du benötigst.
                      </p>
                    </div>

                    <div className="grid grid-cols-1 gap-4 mb-6 sm:grid-cols-2">
                      <div>
                        <CheckboxCard
                          label="Neubau"
                          name="Nutzen"
                          value="Neubau"
                          id="neubau"
                          svg={svg.neubau}
                        />
                      </div>
                      <div>
                        <CheckboxCard
                          label="Hauskauf"
                          name="Nutzen"
                          value="Hauskauf"
                          id="hauskauf"
                          svg={svg.hauskauf}
                        />
                      </div>
                      <div>
                        <CheckboxCard
                          label="Anschlussfinanzierung"
                          name="Nutzen"
                          value="Anschlussfinanzierung"
                          id="anschluss-finanzierung"
                          svg={svg.anschluss}
                        />
                      </div>
                      <div>
                        <CheckboxCard
                          label="Umschuldung"
                          name="Nutzen"
                          value="Umschuldung"
                          id="umschuldung"
                          svg={svg.umschuldung}
                        />
                      </div>
                    </div>
                  </div>
                  <FormMainData />
                </Wizard>
              </form>
            </RequestRightCol>
          </div>
          <div className="w-full p-8 bg-gray-100 lg:w-4/12 lg:order-1 lg:p-16 ">
            <RequestLeftCol />
          </div>
        </div>
      </section>
    </Layout>
  )
}
